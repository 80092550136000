import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import { Component } from '@/types/interfaces';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import React, { useEffect } from 'react';

interface AccordionIconProps {
  id: string;
  variant?: 'small' | 'medium' | 'large';
  className?: string;
}

export interface AccordionProps extends Component {
  title: React.ReactNode;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  transition?: boolean;
  analyticsEvent?: AnalyticsEvent;
  buttonClassName?: string;
  icon?: AccordionIconProps;
  disabled?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  expanded = false,
  setExpanded,
  transition = false,
  analyticsEvent,
  icon,
  buttonClassName = '',
  disabled,
  ...props
}) => {
  const [bodyHeight, setBodyHeight] = React.useState(expanded ? -1 : 0);
  const bodyEl = React.useRef<HTMLElement>(null);
  const { handleActionTracking } = useAnalytics();

  useEffect(() => {
    if (expanded) {
      setBodyHeight(-1);
    } else {
      setBodyHeight(0);
    }
  }, [expanded]);

  let iconSize = 'h-xl w-xl';
  if (icon?.variant === 'small') {
    iconSize = 'h-l w-l';
  } else if (icon?.variant === 'medium') {
    iconSize = 'h-[20px] w-[20px]';
  }

  let ExpandIcon: JSX.Element;
  const expandIconClass = `${icon?.className} ${iconSize} h-6 w-6 flex-none`;

  if (icon?.id === 'chevron') {
    if (bodyHeight !== 0) {
      ExpandIcon = <ChevronUpIcon className={expandIconClass} />;
    } else {
      ExpandIcon = <ChevronDownIcon className={expandIconClass} />;
    }
  } else if (bodyHeight !== 0) {
    ExpandIcon = <MinusIcon className={expandIconClass} />;
  } else {
    ExpandIcon = <PlusIcon className={expandIconClass} />;
  }

  return (
    <article
      {...props}
      className={`${props.className} ${disabled ? 'opacity-50' : ''}`}
    >
      <button
        className={`mb-2 flex w-full items-center justify-between px-0 text-left ${buttonClassName}`}
        disabled={disabled}
        onClick={() => {
          if (bodyHeight === 0 && analyticsEvent) {
            handleActionTracking(analyticsEvent);
          }
          setBodyHeight(
            bodyHeight !== 0
              ? 0
              : (transition ? bodyEl.current?.offsetHeight : -1) || -1
          );
          if (setExpanded) {
            setExpanded(!expanded);
          }
        }}
      >
        {title}
        {!disabled && ExpandIcon}
      </button>
      <section
        className={`${bodyHeight === 0 ? 'overflow-hidden' : ''} transition-all`}
        style={{
          height: bodyHeight < 0 ? 'auto' : `${bodyHeight}px`,
        }}
        aria-hidden={bodyHeight === 0}
      >
        <section ref={bodyEl}>{children}</section>
      </section>
    </article>
  );
};

export default Accordion;
